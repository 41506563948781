<template>
  <div class="d-flex" style="height: 100%">
    <aside id="sidebar" :class="[open ? 'show' : '']">
      <ul class="sidebar-nav">
        <li :class="{ 'sidebar-item': true, active: isCurrentRoute('/') }">
          <RouterLink class="d-flex align-items-center sidebar-link" to="/">
            <i class="lni lni-home"></i> <span>DashBoard</span>
          </RouterLink>
        </li>
        <li :class="{ 'sidebar-item': true, active: isCurrentRoute('/ip') }">
          <RouterLink class="d-flex align-items-center sidebar-link" to="/ip">
            <i class="lni lni-cog"></i> <span>IP Whitelist</span>
          </RouterLink>
        </li>
     

        <li
          :class="{ 'sidebar-item': true, active: isCurrentRoute('/players') }"
        >
          <RouterLink
            class="d-flex align-items-center sidebar-link"
            to="/players"
          >
            <i class="lni lni-users"></i> <span>Players</span>
          </RouterLink>
        </li>
        <li
          :class="{ 'sidebar-item': true, active: isCurrentRoute('/transaction') }"
        >
          <RouterLink
            class="d-flex align-items-center sidebar-link"
            to="/transaction"
          >
            <i class="lni lni-money-protection"></i> <span>Transações</span>
          </RouterLink>
        </li>
        <li
          :class="{ 'sidebar-item': true, active: isCurrentRoute('/games') }"
        >
          <RouterLink
            class="d-flex align-items-center sidebar-link"
            to="/games"
          >
            <i class="lni lni-game"></i> <span>Jogos</span>
          </RouterLink>
        </li>
      
        <li
          :class="{ 'sidebar-item': true, active: isCurrentRoute('/documentation') }"
        >
          <RouterLink
            class="d-flex align-items-center sidebar-link"
            to="/documentation"
          >
            <i class="lni lni-layout"></i> <span>Documentação</span>
          </RouterLink>
        </li>

        <li
        class="sidebar-item"
        >
          <a class="d-flex align-items-center sidebar-link" data-bs-toggle="modal" data-bs-target="#updateAgentModal">
            <i class="lni lni-user" ></i> <span>Perfil</span>
          </a>
        </li>

      </ul>
    </aside>
    <div class="col-1 bodyCurve"></div>
    <div class="row" :class="[open ? 'openMenu' : '']" id="rowhamburger">
      <div
        @click="open ? closeMenu() : openMenu()"
        class="hamburger"
        id="btnHamburger"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <EditProfile></EditProfile>

</template>
<script>
import { useRoute } from "vue-router";
import EditProfile from "../Modals/EditProfile.vue";

export default {
  name: "SideBar",
  components: {
    EditProfile
  },
  data() {
    return {
      open: false,
      
    };
  },
  setup() {
    const route = useRoute();

    function isCurrentRoute(path) {
      return route.path === path;
    }

    return {
      isCurrentRoute,
    };
  },

  methods: {
    openMenu() {
      this.open = true;
    },
    closeMenu() {
      this.open = false;
    },
    toggleSubMenu(menu) {
      this.subMenu[menu] = !this.subMenu[menu];
    },
  },
};
</script>
<style>
.bodyCurve {
  background-color: var(--color-primary-clear);
  border-top-left-radius: 80px;
  margin-top: 50px;
  margin-left: -15px;
  width: 18px !important;
  z-index: 1000;
  display: flex;
}
#sidebar {
  width: 281px;
  min-width: 260px;
  z-index: 1000;
  height: 100%;
  transition: all 0.25s ease-in-out;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
}

.sidebar-nav {
  display: flex;
  padding: 2rem 0;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
}

.sidebar-nav hr {
  margin: 1rem auto;
  width: 70%;
  height: 3px;
  border-radius: 20px;
  background-color: var(--color-text);
}

a.sidebar-link {
  padding: 11px;
  cursor: pointer;
  color: var(--color-text);
  display: block;
  font-size: 17px;
  white-space: nowrap;
  border-left: 3px solid transparent;
}
.sidebar-sub-link {
  color: var(--color-text);

}
.active {
  content: "";
  position: absolute;
  inset: 0px;
  display: block;
  border-radius: 0.75rem;
  border-bottom-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgb(255 255 255 / 0.08);
}
.sidebar-link i {
  font-size: 17px;
  margin-right: 0.75rem;
}

.sidebar-item {
  position: relative;
  width: 185px;
  margin-bottom: 10px;
  transition: 0.5s;
  border-radius: 0.75rem;
}
.sidebar-item:hover {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgb(255 255 255 / 0.08);
  transition: 0.5s;
}
#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
  position: absolute;
  top: 0;
  left: 70px;
  background-color: rgb(37, 147, 180);
  padding: 0;
  min-width: 15rem;
  display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown + .sidebar-dropdown {
  display: block;
  max-height: 15em;
  width: 100%;
  opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 0.075rem 0.075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg);
  transition: all 0.2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
@media (max-width: 650px) {
  .bodyCurve {
    display: none;
  }
  #sidebar {
    display: none;
    position: fixed;
    top: 0;
    width: 202px;
    left: 0;
    height: 100%;
    z-index: 9999;
  }
  #sidebar.show {
    display: block;
  }

  #rowhamburger {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .bodyContent {
    border-radius: 0;
  }

  .hamburger {
    display: block;
  }

  .openMenu {
    left: 266px !important;
  }
  .openMenu .hamburger span:nth-child(1) {
    transform: rotate(45deg);
  }
  .openMenu .hamburger span:nth-child(2) {
    transform: rotate(135deg);
    top: 0;
  }
  .openMenu .hamburger span:nth-child(3) {
    display: none;
    opacity: 0;
  }
  .hamburger {
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-bottom: 35px;
  }

  .hamburger span {
    display: block;
    background: #000;
    position: absolute;
    height: 3px;
    width: 100%;
    transition: 0.3s ease;
  }

  .hamburger span:nth-child(1) {
    top: 0;
  }

  .hamburger span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  .hamburger span:nth-child(3) {
    bottom: 0;
  }

  .hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 50%;
  }

  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 50%;
  }
}
</style>
