<template>
    <div class="modal fade" id="updateAgentModal" tabindex="-1" aria-labelledby="updateAgentModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateAgentModalLabel">Meu perfil</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="token" class="form-label">Token</label>
                <input type="text" readonly v-model="agenteA.agent_token" class="form-control" id="token">
              </div>
             
              <div class="col-md-6">
                <label for="segredo" class="form-label">Segredo</label>
                <input type="text" readonly v-model="agenteA.agent_secret" class="form-control" id="segredo">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="passwordInput" class="form-label">Senha</label>
                <input type="password" v-model="agenteA.password" class="form-control" id="passwordInput">
              </div>
             
              <div class="col-md-6">
                <label for="memoInput" class="form-label">Código</label>
                <input type="text" v-model="agenteA.agent_code" readonly class="form-control" id="memoInput">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="percentInput" class="form-label">RTP (%)</label>
                <input type="text" v-model="agenteA.rtp" class="form-control" id="percentInput">
              </div>
              <div class="col-md-6 form-switch d-flex flex-column justify-content-center pl-0" style="padding-left: 12px !important;">
                <label for="percentInput" class="form-label">Bônus ?</label>
                <input class="form-check-input" style="right: 0px; left: 49px; position: relative;" role="switch" type="checkbox" :true-value="1" :false-value="0" v-model="agenteA.bonus_enable" />
              </div>
              
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <label for="siteEndpointInput" class="form-label">URL de callback</label>
                <input type="text" class="form-control" v-model="agenteA.url" id="siteEndpointInput">
              </div>
              <div class="col-md-6 form-switch d-flex flex-column justify-content-center pl-0" style="padding-left: 13px;">
                <label for="siteEndpointInput" class="form-label">Limite de aposta?</label>
                <input class="form-check-input" style="right: 0px; left: 49px; position: relative;" role="switch" @change="limite = !limite" type="checkbox" :true-value="1" :false-value="0" v-model="agenteA.limit_enable" />
              </div>
              
            </div>
            <div class="row mb-3">
              <div v-if="limite" class="col-md-6 form-switch d-flex flex-column pl-0" style="padding-left: 13px;">
                <label for="siteEndpointInput" class="form-label">Quantia de limite?</label>
                <input type="text" class="form-control" v-model="agenteA.limite_amount" id="limite_amountndpointInput">
              </div>
              <div v-if="limite" class="col-md-6 form-switch d-flex flex-column pl-0" style="padding-left: 13px;">
                <label for="siteEndpointInput" class="form-label">Quantidade de horas?</label>
                <input type="text" class="form-control" v-model="agenteA.limit_hours" id="limite_amountndpointInput">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6 form-switch d-flex flex-column pl-0" style="padding-left: 12px !important;">
                <label for="moeda" class="form-label">Moeda?</label>
                <el-select
                v-model="agenteA.currency"
                filterable
                default-first-option
                :reserve-keyword="false"
                placeholder="Selecione a moeda"
                style="width: 240px; border-radius: 10px;"
              >
                <el-option
                  key="0"
                  label="Real Brasileiro (BRL)"
                  value="BRL"
                />
                <el-option
                  key="1"
                  label="Dolar (USD)"
                  value="USD"
                />
                <el-option
                  key="2"
                  label="Euro (EUR)"
                  value="EUR"
                />
                <el-option
                  key="3"
                  label="Guarani (PYG)"
                  value="PYG"
                />
                <el-option
                  key="4"
                  label="Iene japonês (JPY)"
                  value="JPY"
                />
                <el-option
                  key="5"
                  label="Rublo russo (RUR)"
                  value="RUR"
                />
                <el-option
                  key="5"
                  label="Taka bengali (BDT)"
                  value="BDT"
                />
                <el-option
                  key="5"
                  label="Yuan (RMB)"
                  value="RMB"
                />
                <el-option
                  key="5"
                  label="Baht (THB)"
                  value="THB"
                />
                <el-option
                  key="5"
                  label="Rupia (INR)"
                  value="INR"
                />
                <el-option
                  key="5"
                  label="Peso Filipino (PHP)"
                  value="PHP"
                />
                <el-option
                  key="5"
                  label="Birr etíope (ETB)"
                  value="ETB"
                />
              </el-select>
              </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" class="btn" style="background-color: var(--color-primary);color: white;" id="updateAgentButton" @click="updateAgent">Atualizar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from 'vue-toast-notification';
  import { ElSelect } from "element-plus";

  export default {
    name: "ProfilePage",
    components: {
      ElSelect
    },
    data() {
      return {
        toast: null,
        agenteA: [],
        limite: false,
      };
    },
    mounted() {
        
        this.toast = useToast({ position: "top-right" });
        this.getInfos();
    },
    methods: {
        async getInfos() {
            var token = localStorage.getItem("jwt-token");
            await axios
                .get(process.env.VUE_APP_API_URL + "/api/agentes/auth/me", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((data) => {
                    console.log(data.data.agente)
                    this.agenteA = data.data.agente;
                    this.limite = data.data.agente.limit_enable;

                })
                .catch(() => {
                    this.toast.error(
                        "Ocorreu um erro interno ao recuperar suas informações"
                    );
                });
                },
        async updateAgent() {
            try {
            const token = localStorage.getItem("jwt-token");
            await axios.put(
                `${process.env.VUE_APP_API_URL}/api/agentes/profile`,
                {
                    "password": this.agenteA.password,
                    "rtp": this.agenteA.rtp,
                    "bonus_enable": this.agenteA.bonus_enable,
                    "url":  this.agenteA.url,
                    "limit_enable": this.agenteA.limit_enable,
                    "limite_amount": this.agenteA.limite_amount,
                    "limit_hours": this.agenteA.limit_hours,
                    "currency": this.agenteA.currency
                },
                {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                }
            );
            this.toast.success("Agente atualizado com sucesso");
            this.$emit('getInfos');
            } catch (error) {
            this.toast.error(
                error?.response?.data?.msg || "Ocorreu um erro interno"
            );
            }
      },
    },
  };
  </script>
  